<template>
  <div>
    <div style="min-height:520px;">
      <div style="background: #f7f7f7;">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ordermain',
  data() {
    return {
      list: [
        { name: '下单', path: '/cp/create', active: true },
        { name: '我的订单', path: '/cp/list', active: false }
      ]
    };
  },
  mounted() {
    this.$router.push({ path: '/cp/list' });
  },
  methods: {}
};
</script>

<style>
.el-dialog {
  border-radius: 8px;
}
</style>

<style scoped></style>
